import { ErrorHandlerMixin, STUCK_CLAIMS_IMPORT_HISTORY } from "@kraftheinz/common";
import { n as normalizeComponent, a as StuckClaimsUtils, T as TYPE_ICON } from "./index.js";
import "vue";
import "lodash";
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-modal", { attrs: { "title": "Import Data", "visible": _vm.visible, "after-close": _vm.afterModalClose }, on: { "ok": _vm.handleOk, "cancel": _vm.handleCancel } }, [_c("a-radio-group", { model: { value: _vm.selectedValue, callback: function($$v) {
    _vm.selectedValue = $$v;
  }, expression: "selectedValue" } }, [_c("a-radio", { staticClass: "d-block mb-3", attrs: { "value": "promo" } }, [_vm._v("Step 1 - Import Promo")]), _c("a-radio", { attrs: { "value": "claim" } }, [_vm._v("Step 2 - Import Claim")])], 1)], 1);
};
var staticRenderFns$2 = [];
const __vue2_script$2 = {
  name: "CountryModal",
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedValue: "promo"
    };
  },
  methods: {
    async handleOk() {
      this.$emit("onImportData", this.selectedValue);
      this.handleCancel();
    },
    handleCancel() {
      this.$emit("update:visible", false);
    },
    afterModalClose() {
      this.selectedValue = "promo";
    }
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, null, null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var ImportModal = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("list-composer", { attrs: { "title": "Import History", "has-active": false, "has-filter-inactive": false, "has-advanced-search": false, "has-filter": false, "has-action": true, "loading": _vm.isLoading, "custom-query": _vm.customQueryString, "only-custom-query": true, "search-by": "" }, scopedSlots: _vm._u([{ key: "custom-buttons", fn: function() {
    return [_c("a-button", { attrs: { "type": "primary", "loading": _vm.isImport }, on: { "click": _vm.importData } }, [_vm._v(" Import Data ")])];
  }, proxy: true }, { key: "action", fn: function(ref) {
    var record = ref.record;
    return [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Download")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "download", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.handleDownload(record);
    } } })], 1)];
  } }]) }, [_c("tag-field", { key: "Type", attrs: { "data-index": "type", "title": "Upload Type", "sorter": true, "color": _vm.getTypeColor, "width": 150 } }), _c("text-field", { key: "FileName", attrs: { "title": "File Name", "data-index": "fileName", "sorter": true, "width": 300 } }), _c("text-field", { key: "LastModified", attrs: { "title": "Date & Time", "data-index": "lastModified", "parse": _vm.formatDate, "sorter": true, "width": 150 } }), _c("text-field", { key: "Status", attrs: { "title": "Status", "data-index": "status", "sorter": true, "parse": _vm.formatStatus, "width": 250 } }), _c("text-field", { key: "description", attrs: { "title": "Reason", "data-index": "description", "sorter": true } }), _c("text-field", { key: "TotalClaims", attrs: { "title": "Total Imported Claims", "data-index": "totalClaims", "sorter": true, "width": 200 } }), _c("text-field", { key: "TotalPromos", attrs: { "title": "Total Imported Promos", "data-index": "totalPromos", "sorter": true, "width": 200 } })], 1), _c("import-modal", { attrs: { "visible": _vm.visible }, on: { "update:visible": function($event) {
    _vm.visible = $event;
  }, "onImportData": _vm.onImportData } })], 1);
};
var staticRenderFns$1 = [];
var ImportHistory_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "ImportHistory",
  components: { ImportModal },
  mixins: [StuckClaimsUtils, ErrorHandlerMixin],
  inject: ["crud"],
  data() {
    return {
      isLoading: false,
      customQueryString: `timeZone=${this.$moment().format("Z")}`,
      exportFileUrl: `${apiUrl$1}/stuck-claims/administration/import-history/export`,
      importDataUrl: `${apiUrl$1}/stuck-claims/import`,
      isImport: false,
      visible: false
    };
  },
  methods: {
    async handleDownload(record) {
      this.isLoading = true;
      try {
        await this.downloadFileUtils(`${this.exportFileUrl}/${record.id}`, "application/vnd.ms-excel");
      } catch (error) {
        this.displayErrorNotification(error);
      } finally {
        this.isLoading = false;
      }
    },
    formatStatus(_, record) {
      return `${record.status} - ${record.percent || 0}%`;
    },
    formatDate(value) {
      if (!value)
        return "";
      return this.$moment(value).format("DD/MM/YYYY HH:mm");
    },
    getTypeColor(_, record) {
      return TYPE_ICON[record.type.toLowerCase()];
    },
    importData() {
      this.visible = true;
    },
    async onImportData(type) {
      this.isImport = true;
      try {
        await this.axios.get(this.importDataUrl + `?type=${type}`);
      } catch (error) {
        this.displayErrorNotification(error);
      } finally {
        this.isImport = false;
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ImportHistory = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "stuck-claims.administration.import-history", "api-url": _vm.apiUrl } }, [_c("import-history")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { ImportHistory },
  data() {
    return {
      apiUrl,
      page: STUCK_CLAIMS_IMPORT_HISTORY,
      itemsMenu: [
        {
          key: "administration",
          title: "Administration",
          path: ""
        },
        {
          key: "importHistory",
          title: "Import History",
          path: "import-history"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
